import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Countdown from "react-countdown"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Index(props) {
  const { post } = props.data
  const Complete = () => (
    <p>
      The Summit has started! If you have already registered,{" "}
      <a href="https://download.socio.events/event/MzEyNTU=?token=eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7InR5cGUiOiJQcm9tb1BhZ2UiLCJldmVudF9pZCI6MzEyNTUsImFwcF9pZCI6MjYwMn0sImlzcyI6Imh0dHBzOi8vc29jaW8uZXZlbnRzIiwiaWF0IjoxNzEwODMyODkwfQ.k8a78xTyhQoqEGzXpHD1PIyp1OzCg8x2jP828nqrJjA">
        click here to start watching now
      </a>
      .<br />
      <small>
        Having trouble logging in?{" "}
        <a href="https://docs.google.com/document/d/1sH3-MlBTMT7YTKSLopO8aK9Q77dfBgXHi47GUhXf9zU/edit?usp=sharing">
          <b>Check out our troubleshooting doc</b>
        </a>
        .
      </small>
    </p>
  )

  return (
    <Layout>
      <div className={post.pageACF.colorScheme + " home relative"}>
        <div className="relative">
          <div className="">
            <div
              className="absolute -z-20 opacity-40 h-full max-h-[500px] md:max-h-[700px] lg:max-h-[1024px] w-full top-0 bg-cover bg-center"
              style={{
                backgroundImage:
                  "url(" + post.featuredImage.node.resize.src + ")",
              }}
            >
              <div className="absolute -z-10 h-full w-full top-0 mix-blend-screen"></div>
              <div
                className="absolute -z-10 h-[200px] sm:h-[150px] md:h-[200px] lg:h-[400px] bottom-0 w-full bg-contain"
                style={{
                  backgroundImage:
                    "url(https://wp.transtechsocial.org/wp-content/uploads/2023/01/white-fade-2.png)",
                }}
              ></div>
            </div>
          </div>
          <div className={post.pageACF.colorScheme + " " + post.slug + " page"}>
            <div id="title" className="max-w-7xl mx-auto px-4 sm:px-8">
              <div className="home-title w-full entry-content pt-32 xl:pt-48 pb-8 relative z-0 opacity-100">
                <h1
                  itemProp="headline"
                  className="w-full font-hero text-4xl md:text-6xl lg:text-7xl leading-none text-black mb-4 md:mb-12 lg:mb-24"
                >
                  Beyond the Code
                </h1>
                <div className="home-form w-full">
                  <p className="text-left text-lg max-w-[500px] lg:max-w-[650px] !mx-0">
                    The <b>TransTech Summit,</b> our FREE* annual online event
                    was specifically created for the most marginalized members
                    of the LGBTQIA+ community. Created by members of the
                    community, for the community, to lift ourselves up through
                    education and employment.
                  </p>

                  {/*<div className="is-content-justification-start is-layout-flex wp-container-35 wp-block-buttons">
                    <div className="wp-block-button">
                      <a
                        className="wp-block-button__link has-orange-to-purple-gradient-background has-background wp-element-button"
                        href="https://registration.socio.events/e/2024transtechsummit"
                        target="_blank"
                      >
                        Reserve Your Ticket
                      </a>
                    </div>
                  </div>*/}
                  <p class="text-left !mx-0">
                    The 2024 Summit is complete! If you were an all-access pass
                    holder,{" "}
                    <a href="https://www.transtechsocial.com/library-v2">
                      click here to continue watching now
                    </a>
                    .<br />
                    <small>
                      Having trouble logging in?{" "}
                      <a href="https://docs.google.com/document/d/1OEwimkVM-6Fk4aYGsn57XcYjO5jaOYcsMKi9ohnFnVY/edit?usp=sharing">
                        <b>Check out our troubleshooting doc</b>
                      </a>
                      .
                    </small>
                  </p>
                  {/*<Countdown
                    date={1711634400000}
                    renderer={props => (
                      <p className="text-left text-lg max-w-[500px] lg:max-w-[650px] !mx-0">
                        <i>
                          The summit begins in&nbsp;
                          {props.days === 1 && props.days + " day "}
                          {props.days > 1 && props.days + " days "}
                          {props.days >= 0 &&
                            props.hours > 1 &&
                            props.hours + " hours and "}
                          {props.days >= 0 &&
                            props.hours === 1 &&
                            props.hours + " hour and "}
                          {props.minutes !== 1 && props.minutes + " minutes"}
                          {props.minutes === 1 && props.minutes + " minute"}
                        </i>
                      </p>
                    )}
                  >
                    <Complete />
                  </Countdown>*/}
                  {/*<p className="!mx-0">
                    Registration for the 2025 Summit is not yet open. Become a
                    member to access resources right away and stay informed for
                    the 2025 Summit.
                  </p>
                  <div className="is-content-justification-start is-layout-flex wp-container-35 wp-block-buttons">
                    <div className="wp-block-button">
                      <a
                        className="wp-block-button__link has-orange-to-purple-gradient-background has-background wp-element-button"
                        href="https://transtechsocial.org/join"
                      >
                        Become a Member
                      </a>
                    </div>
                  </div>*/}
                  {/*<p className="!mx-0">
                    The Summit has started! If you have already registered,{" "}
                    <a href="https://download.socio.events/event/MjA1MjI=?token=eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7InR5cGUiOiJQcm9tb1BhZ2UiLCJldmVudF9pZCI6MjA1MjIsImFwcF9pZCI6MjYwMn0sImlzcyI6Imh0dHBzOi8vc29jaW8uZXZlbnRzIiwiaWF0IjoxNjc3Nzg3NjQ3fQ.lxg5O5hVU4EX81z7JBr-KGaTLQwfXSjuAS51YReF9Sg">
                      <b>click here to start watching now</b>
                    </a>
                    .<br />
                    <small>
                      Having trouble logging in?{" "}
                      <a href="https://docs.google.com/document/d/1sH3-MlBTMT7YTKSLopO8aK9Q77dfBgXHi47GUhXf9zU/edit?usp=sharing">
                        <b>Check out our troubleshooting doc</b>
                      </a>
                      .
                    </small>
                  </p>*/}
                  <p className="!mx-0 !text-base">
                    2025 Dates TBA.{" "}
                    <a href="https://transtechsocial.org/join/">
                      Become a member to be the first to know!
                    </a>
                  </p>
                  {/*<p className="!mx-0 !text-base">
                    *Those who become a member prior to March 16, 2024 receive
                    free registration.
                  </p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="page-content" className="max-w-7xl mx-auto px-4 sm:px-8">
          <div className="relative flex flex-col lg:flex-row justify-between gap-8 mb-32">
            <section
              id="blocks"
              itemProp="articleBody"
              className="entry-content w-full"
            >
              {!!post.content && parse(post.content)}
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = props => {
  const { post } = props.data
  return (
    <Seo
      title={parse(post.title) + " | TransTech Summit"}
      description={post.smartcrawlSeo?.metaDesc}
      ogTitle={post.opengraphTitle}
      ogDescription={post.smartcrawlSeo?.opengraphDescription}
      twTitle={post.smartcrawlSeo?.twitterTitle}
      twDescription={post.smartcrawlSeo?.twitterDescription}
      featuredImage={post.featuredImage?.node?.mediaItemUrl}
      ogImage={post.smartcrawlSeo?.opengraphImage?.mediaItemUrl}
      twImage={post.smartcrawlSeo?.twitterImage?.mediaItemUrl}
    />
  )
}

export const query = graphql`
  {
    post: wpPage(isFrontPage: { eq: true }) {
      id
      content
      title
      slug
      date(formatString: "MMMM DD, YYYY")
      jotformUrl {
        jotformUrl
      }
      pageACF {
        colorScheme
      }
      featuredImage {
        node {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                webpOptions: { quality: 90 }
                width: 1920
                formats: WEBP
                layout: FIXED
                aspectRatio: 1.778
              )
            }
          }
          resize(
            fit: COVER
            width: 1920
            aspectRatio: 1.778
            quality: 90
            format: WEBP
          ) {
            height
            src
            width
          }
        }
      }
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
    }
  }
`
